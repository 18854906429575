/* ------------------------- Responsive Banner --------------------------- */
@media (max-width : 320px) {
    .banner_heading{
        font-size: 35px !important;
        line-height: 44px !important;
        width: 90% !important;
        margin-left: 0px !important;
    }
    .pharmcy_supply_main{
        width: 95% !important;
        margin: auto;
    }
    .pharmcy_supply_main .heading{
        font-size: 31px !important;
        line-height: 40px !important;
    }
    .pharmcy_supply_main .subhead{
        font-size: 15px !important;
    }
    .input_section{
        margin: 20px 0px 40px !important;
    }
    .input_section input{
        padding-left: 40px !important;
    }
    .pharmacy_supply_img{
        width: 95% !important;
        margin: auto;
    }
  }


/* @media only screen and (min-width: 375px) {
    .banner_heading{
        width: 95% !important;
        margin-left: 20px !important;
    }
}
@media only screen and (min-width: 390px) {
    .banner_heading{
        width: 91% !important;
        margin-left: 15px !important;
    }
}
@media only screen and (min-width: 430px) {
    .banner_heading{
        width: 91% !important;
        margin-left: 15px !important;
    }
} */