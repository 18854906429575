/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

*{
  padding: 0;
  margin: 0;
  /* background-color: #f9f9f9; */
}
.anahad_bg_img{
  background-image: url(/public/Image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: aqua; */
}
.tableheadcell{
  font-size: 10px !important;
  font-weight: bold !important;
  color: #A0AEC0 !important;
  text-transform: uppercase !important;
  font-family: sans-serif !important;
  text-align: left !important;
}
.tablerowcell{
  color: #2D3748 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-bottom: 1px solid #E2E8F0 !important;
  text-align: left !important;
  padding: 16px 8px !important;
}

.productTableheadcell{
  font-weight: bold !important;
  /* font-size: 15px !important; */
  padding: 12px 16px !important;
}
.productTablecell{
  padding: 11px 16px !important;
  background: white !important;
  font-family: 'InterRegular', sans-serif !important; 
}

.hero-title {
  font-family: "Mukta", sans-serif;
  font-size: 100px;
  line-height: 100px;
  font-weight: lighter;
  margin-bottom: 16px;
}

.hero-media {
    position: relative;
}
.hero-copy {
  margin: auto;
  /* padding-top: 40px; */
  padding-right: 48px;
  min-width: 448px;
  max-width: 512px;
  z-index: 1;
}
.hero-paragraph {
  /* font-weight: regular; */
  color: #000;
  letter-spacing: 1px;
  margin-bottom: 32px;
  margin-top: 15px !important;
}
.hero-paragraph-green {
  font-weight: bold;
  font-size: larger;
  color: #71ca9b;
  letter-spacing: 1px;
  margin-bottom: 32px;
}

.hero-media-image {
  margin: auto;
  border-radius: 4px;
  transform: perspective(1000px) rotateY(16deg) rotateX(2deg) rotateZ(-7deg) scaleY(0.95) translateX(2%);
  max-width: 580px;
  opacity: 0; /* Start invisible for fade-in effect */
  will-change: transform, opacity;
}

/* Add animation when loaded */
.is-loaded .hero-media-image {
  -webkit-animation: fadeInLeftMedia 1s 0.2s both cubic-bezier(0.3, 0, 0.2, 1);
  animation: fadeInLeftMedia 1s 0.2s both cubic-bezier(0.3, 0, 0.2, 1);
}

.pharmacy_input::placeholder {
  color: #CFCFCF; 
  font-size: 14px;
  font-family: 'GeneralSansRegular', sans-serif;
}

@font-face {
  font-family: 'Kamerik205Heavy';
  src: url('../public/fonts/kamerik-205-heavy.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Kamerik205Regular3'; /* Choose a name to use in your CSS */
  src: url('../public/fonts/Kamerik205\ Regular\ 3.ttf') format('truetype'); /* Ensure the path is correct */
  font-weight: normal; /* Adjust based on the font's weight */
  font-style: normal; /* Use 'italic' if the font is an italic version */
}
@font-face {
  font-family: 'MuktaExtraBold';
  src: url('../public/fonts/Mukta-ExtraBold.ttf') format('truetype');
  font-weight: 800; 
  font-style: normal;
}
@font-face {
  font-family: 'PoppinsLight';
  src: url('../public/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300; 
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSansSemibold';
  src: url('../public/fonts/General-Sans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSansRegular'; 
  src: url('../public/fonts/GeneralSans-Regular.otf') format('opentype'); 
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSansMedium'; 
  src: url('../public/fonts/GeneralSans-Medium.otf') format('opentype'); 
  font-weight: 400; 
  font-style: normal; 
}

@font-face{
  font-family: 'InterRegular';
  src: url('../public/fonts/inter-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Define the keyframes for the animation */
@keyframes fadeInLeftMedia {
  0% {
    opacity: 0;
    transform: perspective(1000px) rotateY(16deg) rotateX(2deg) rotateZ(-7deg) scaleY(0.95) translateX(2%);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) rotateY(0) rotateX(0) rotateZ(0) scaleY(1) translateX(0);
  }
}

